.navbar-container {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	padding: 15px 50px 0;
	z-index: 99;
	background-color: $el-quinary;
	@include tablet-breakpoint {
		padding: 15px 30px 0;
	}
	&__title {
		font-size: 1.5rem;
		font-weight: 400;
	}
	&__logo {
		img {
			width: 160px;
			object-fit: cover;
			@include tablet-breakpoint {
				width: 140px;
			}
		}
	}
	&__links {
		font-size: 1.3rem;
		font-weight: 400;
		display: flex;
		.link {
			position: relative;
			margin-left: 30px;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $text-primary;
				transform: scaleX(0);
				transform-origin: left;
				transition: $flash-animation ease-in-out;
			}
			&:hover {
				&::after {
					transform: scaleX(100%);
				}
			}
			img {
				width: 20px;
				object-fit: contain;
				filter: brightness(0) saturate(100%) invert(17%) sepia(25%) saturate(3%) hue-rotate(314deg) brightness(86%) contrast(84%);
			}
		}
	}
	&__links.mobile {
		display: none;
	}
	@include mobile-breakpoint {
	&__links.desktop {
			display: none;
		}
		&__links.mobile {
			display: flex;
		}
	}
}
