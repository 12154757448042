.services-section {
	width: 100%;
	padding: 12px 0 12px;
	&__content {
		height: 100%;
        width: 90%;
		margin: 0 auto;
		max-width: 1300px;
		h2 {
            font-size: 3rem;
            font-weight: 400;
            margin-bottom: 32px;
            @include tablet-breakpoint {
                position: block;
                overflow-x: visible;
            }
        }
        h3 {
            font-size: 1.4em;
            font-weight: 500;
            color: #2d2d2d;
            margin: 32px 0 23px 46px;
            @include tablet-breakpoint {
                margin-left: 23px;
            }
        }
        .principal-service {
            font-size: 1.8em;
            color: $el-primary;
            font-weight: 500;
            margin: 18px 0 23px 46px;
        }
        p {
            text-align: justify;
            margin-left: 46px;
            @include tablet-breakpoint {
                margin-left: 23px;
            }
        }
        a {
            font-weight: 500;
            text-decoration: underline;
        }
        strong {
            font-weight: 700;
        }
	}
}
