.contact-section {
	width: 100%;
    padding: 24px 0;
    h2 {
        font-size: 3rem;
        font-weight: 400;
        margin-bottom: 40px;
    }
    &__content {
		width: 90%;
		max-width: 1300px;
		height: 80%;
		display: flex;
        flex-direction: row-reverse;
		justify-content: space-between;
		margin: 40px auto 40px;
		// background-color: aqua;
		@include tablet-breakpoint {
			flex-direction: column-reverse;
		}
		&--left-part {
            padding-top: 80px;
			position: relative;
			width: 44%;
			height: 100%;
			margin-top: 10px;
			.item {
				display: flex;
				align-items: center;
				margin-bottom: 35px;
				img {
                    width: 50px;
                    height: 50px;
                    padding: 12px;
                    border-radius: 50%;
                    border: 2px solid;
                    filter: brightness(0) saturate(100%) invert(13%) sepia(25%) saturate(3%) hue-rotate(314deg) brightness(86%) contrast(84%);
                    margin-right: 15px;
				}
				p,
				a {
					color: $text-primary;
                    font-size: 1.3rem;
                    font-weight: 400;
                    cursor: pointer;
					@include mobile-breakpoint {
						font-size: 16px;
						
					}
				}
			}
			.social-links {
				margin-top: 190px;
				@include tablet-breakpoint {
					margin-top: 80px;
					display: flex;
				}
				
				img {                
                    width: 38px;
                    height: 38px;
					object-fit: contain;
                    filter: brightness(0) saturate(100%) invert(13%) sepia(25%) saturate(3%) hue-rotate(314deg) brightness(86%) contrast(84%);
					margin-right: 40px;
                    transition: 200ms;
                    &:hover {
                        transform: scale(1.1);
                    }
				}
			}
		}
		&--right-part {
			width: 45%;
			height: 100%;
			@include tablet-breakpoint {
				width: 100%;
			}
			form {
				position: relative;
				margin: 0 auto;
				@include tablet-breakpoint {
					margin: 0 0 0 150px;
				}
				@include tablet-breakpoint {
					margin: 0 0 50px 0;
				}
				::-webkit-scrollbar {
					width: 4px;
				}
				::-webkit-scrollbar-track {
					background: transparent;
				}
				::-webkit-scrollbar-thumb {
					background: $el-tertiary;
				}
				::-webkit-scrollbar-thumb:hover {
					background: $el-tertiary;
				}
				label {
					position: relative;
					.error {
						position: absolute;
						bottom: 30px;
						left: 50%;
						transform: translateX(-50%);
						color: $text-primary;
					}
				}
				input[type="text"],
				input[type="email"],
				input[type="submit"],
				textarea {
					width: 100%;
					padding: 15px 16px;
					margin-bottom: 15px;
					border: 2px solid $text-primary;
					font-size: 20px;
					color: $text-primary;
					background-color: transparent;
					resize: none;
					&:focus {
						border: 2px solid $text-primary;
					}
					&::placeholder {
                        font-size: 1.3rem;
						color: rgba($text-primary, 0.8)
					}
					// &:invalid {
					// 	animation: input-error 0.2s ease-in-out 2;
					// }
					
					// animation: inputs-fade-in 2200ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
				}
				input[type="text"],
				input[type="email"],
				input[type="submit"] {
					max-width: 400px;
				}
				textarea {
					height: 200px;
				}
				input[type="submit"] {
                    color: $text-primary;
					display: block;
					width: 150px;
					margin: auto;
					cursor: pointer;
					transition: 100ms;
					&:focus {
						color: $text-primary;
						border: 2 solid $text-primary;
					}
					&:active {
						color: $text-primary;
						border: 2px solid $text-primary;
						transform: scale(0.98);
					}
				}
				
			}
		}
	}
}


