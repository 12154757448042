.author-section {
	width: 100%;
	padding: 122px 0 42px;
	&__content {
		display: flex;
		width: 100%;
		height: 100%;
		max-width: 1450px;
		margin: 0 auto;
		@include tablet-breakpoint {
			flex-direction: column-reverse;
		}
		&--picture {
			position: relative;
			width: 50%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			@include tablet-breakpoint {
				width: 100%;
				margin-bottom: 30px;
			}
			img {
				width: 60%;
				object-fit: cover;
				@include tablet-breakpoint {
					max-width: 400px;
				}
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 30%;
				right: -5%;
				width: 90%;
				height: 30%;
				background-color: rgba($el-primary, 1);
				z-index: -1;
				@include tablet-breakpoint {
					right: 0;
				}
			}
		}
		&--text {
			width: 50%;
			align-items: center;
			text-align: justify;
			margin: auto;
			padding: 0 10% ; 
			@include tablet-breakpoint {
				width: 100%;
			}
			h1 {
				font-size: 3rem;
				font-weight: 400;
				margin-bottom: 32px;
			}
			p {
				font-size: 1.1rem;
				margin-bottom: 40px;
			}
		}
	}
}
