// fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

// settings
*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto Slab", serif;
	scroll-behavior: smooth;
	text-decoration: none;
	list-style: none;
	border: none;
	color: #2d2d2d;
}
html {
	background-color: #ecf8f8;
	::selection {
		background-color:#eee4e1;
	}
}

// colors
$el-primary: #b2967d;
$el-secondary: #e6beae;
$el-tertiary: #e7d8c9;
$el-quaternary: #eee4e1;
$el-quinary: #ecf8f8;

$text-primary: #2d2d2d;

// scrollbar
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: rgba($el-primary, 0.5);
	border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
	background: rgba($el-primary, 1);
}

// mixins
@mixin desktop-breakpoint {
	@media (max-width: 1200px) {
		@content;
	}
}
@mixin tablet-breakpoint {
	@media (max-width: 900px) {
		@content;
	}
}
@mixin mobile-breakpoint {
	@media (max-width: 550px) {
		@content;
	}
}

// animations
$flash-animation: 200ms;
$short-animation: 500ms;
$long-animation: 700ms;
