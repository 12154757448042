.home-section {
	width: 100%;
	padding-top: 100px;
	@include tablet-breakpoint {
		height: inherit;
	}
	&__hero {
		display: flex;
		width: 100%;
		height: 100%;
		max-width: 1450px;
		margin: 0 auto;
		&--text {
			width: 50%;
			align-items: center;
			padding: 10%;
			margin: auto;
			@include tablet-breakpoint {
				width: 60%;
			}
			h1 {
				font-size: 3rem;
				font-weight: 400;
				margin-bottom: 20px;
				@include tablet-breakpoint {
					position: block;
					overflow-x: visible;
					width: 200%
				}
			}
			p {
				font-size: 1.1rem;
				margin-bottom: 40px;
			}
		}
		&--picture {
			position: relative;
			width: 50%;
			display: flex;
			align-items: center;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: -10%;
				transform: translateY(-50%);
				width: 90%;
				height: 40%;
				background-color: rgba($el-primary, 0.5);
				z-index: -1;
			}
			img {
				height: 70%;
				object-fit: contain;
				@include tablet-breakpoint {
					height: inherit;
					width: 80%;
					margin-top: 140px;
				}
			}
		}
		video {
			width: 95%;
			max-width: 1316px;
			aspect-ratio: 16/9;
			margin: 0 auto;
		}
	}
}
