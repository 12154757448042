.books-section {
	width: 100%;
	overflow-x: scroll;	
	.books-page {
		width: 200%;
		display: flex;
	}
}

.arrow-container {
	position: relative;
	// scrollbar
	::-webkit-scrollbar {
		display: none;
	}
	.arrow {
		position: absolute;
		top: 65%;
		width: 50px;
		height: 50px;
		padding: 10px;
		border-radius: 50%;
		border: 3px solid;
		filter: brightness(0) saturate(100%) invert(68%) sepia(13%) saturate(715%) hue-rotate(347deg) brightness(89%) contrast(86%);
		cursor: pointer;
		&-left {
			right: 20px;
		}
		&-right {
			transform: rotate(180deg);
			left: 20px;
		}
	}
	.inactif {
		opacity: 0.3;
		transition: 0.2s;
	}
}



.book-info {
	width: 100%;
	height: 85vh;
	@include tablet-breakpoint {
		height: inherit;
	}
	&__content {
		display: flex;
		width: 100%;
		height: 100%;
		max-width: 1450px;
		margin: 0 auto;
		&--text {
			width: 50%;
			align-items: center;
			text-align: justify;
			padding: 10%;
			margin: auto;
			h2 {
				font-size: 3rem;
				font-weight: 400;
				margin-bottom: 20px;
				@include tablet-breakpoint {
					position: block;
					overflow-x: visible;
					width: 300%
				}
			}
			p {
				font-size: 1.1rem;
				margin-bottom: 40px;
			}
		}
		&--picture {
			position: relative;
			width: 50%;
			display: flex;
			align-items: center;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: -10%;
				transform: translateY(-50%);
				width: 90%;
				height: 40%;
				background-color: rgba($el-primary, 1);
				z-index: -1;
			}
			img {
				height: 70%;
				object-fit: contain;
				-webkit-box-shadow: 4px 9px 16px 1px rgba(0,0,0,0.65); 
				box-shadow: 4px 9px 16px 1px rgba(0,0,0,0.65);
				@include tablet-breakpoint {
					height: inherit;
					width: 60%;
				}
			}
		}
	}
}
