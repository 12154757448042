.books-info-section {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
    animation: open-info 2000ms cubic-bezier(0.66, 0, 0.3, 0.98);
	overflow: hidden;
	@include tablet-breakpoint {
		overflow-y: scroll;
	}
	&__support {
		position: absolute;
		top: 54%;
		left: 0;
		transform: translateY(-50%) translateX(40%);
		width: 60%;
		height: 40%;
		background-color: rgba(white, 0.5);
		animation: open-support 1350ms cubic-bezier(0.66, 0, 0.3, 0.98) 450ms;
		z-index: -1;
	}
	&__content {
		position: absolute;
		top: 10%;
		left: 11%;
		.up {
			display: flex;
			// margin-bottom: 30px;
			@include tablet-breakpoint {
				flex-direction: column;
				margin-bottom: 40px;
			}
		}
		p {
			color: white;
			line-height: 22px;
		}
		&--resume {
			width: 30%;
			padding: 40px 20px;
			background-color: #0d5f7d94;
			-webkit-box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.45); 
			box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.45); 
			@include tablet-breakpoint {
				width: 90%;
			}
			p {
				text-align: justify;
			}
			h4 {
				margin-bottom: 40px;
				font-size: 1.5em;
				color: white;
				text-align: center;
			}
		}
		&--info {
			display: flex;
			width: 55%;
			max-width: 740px;
			padding: 40px 20px;
			margin-right: 70px;
			background-color:  #083b4e96;
			-webkit-box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.45); 
			box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.45); 
			@include tablet-breakpoint {
				width: 90%;
				margin-bottom: 20px;
			}
			.information {
				width: 50%;
				h5 {
					font-size: 1.2em;
					margin-bottom: 10px;
					color: #add0e2;
				}
				
			}
			.buy {
				position: relative;
				width: 50%;
				padding-left: 20px;
				@include tablet-breakpoint {
					width: 60%;
				}
				p {
					font-size: 1.3em;
					line-height: 33px;
					font-style: italic;
					text-align: justify;
					@include tablet-breakpoint {
						font-size: 16px;
						line-height: normal;
					}
				}
				.btn-brisee {
					position: absolute;
					bottom: 10px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		// .down { 
		// 	display: flex;
		// 	margin-left: 30px;
		// 	.critic-card {
		// 		width: 30%;
		// 		max-width: 470px;
		// 		margin-right: 20px;
		// 		padding: 20px;
		// 		background-color: rgba(#459db6, 0.5);
		// 		-webkit-box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.45); 
		// 		box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.45); 
		// 		h5 {
		// 			color: white;
		// 			font-size: 1em;
		// 			font-weight: 500;
		// 			margin-bottom: 15px;
		// 			text-decoration: underline;
		// 		}
		// 		.rating-star {
		// 			display: flex;
		// 			margin-bottom: 20px;
		// 			img {
		// 				width: 20px;
		// 				filter: invert(83%) sepia(28%) saturate(6873%) hue-rotate(344deg) brightness(104%) contrast(101%);
		// 			}
		// 			p {
		// 				margin-left: 10px;
		// 				font-weight: 700;
		// 			}
		// 		}
		// 	}
		// }
	}
}

.books-info-section-to-close {
    animation: close-info 2000ms cubic-bezier(0.66, 0, 0.3, 0.98);
}
.books-info-section__support-to-close {
	animation: close-support 1450ms cubic-bezier(0.66, 0, 0.3, 0.98);
}

@keyframes open-info {
	from {
        transform: translateX(100%);
	}
	to {
        transform: translateX(0);
	}
}

@keyframes close-info {
	from {
        transform: translateX(0);
	}
	to {
        transform: translateX(100%);
	}
}

@keyframes open-support {
	from {
        transform: translateX(-100%) translateY(-50%);
	}
	to {
        transform: translateX(40%) translateY(-50%);
	}
}
@keyframes close-support {
	from {
        transform: translateX(40%) translateY(-50%);
	}
	to {
        transform: translateX(-100%) translateY(-50%);
	}
}




.books-info-section.brisee {
	background-image: url(../../assets/home/full.jpg);
}


.noel.books-info-section {
	background-image: url(../../assets/home/noel_full.jpg);
	.books-info-section__content {
		&--resume {
			width: 40%;
			background-color: #6caab866;
			@include tablet-breakpoint {
				width: 90%
			}
			p, h4 {
				color: $text-primary;
			}
		}
		&--info {
			width: 45%;
			background-color: #86abba73;
			@include tablet-breakpoint {
				width: 90%
			}
			h5 {
				color: #555755;
			}
			p {
				
				color: $text-primary;
			}
			.btn-noel {
				position: absolute;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	.btn-close {
		color: $text-primary;
		&::after {
			background-color: $text-primary;
		}
	}
}